.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2fr 4.5fr 1.25fr 1.75fr;
    gap: 1rem;
    height: 95%;
}

.grid-item {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
}

.item1,
.item4 {
    grid-column: 1 / -1;
}
item{
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px #000000;
    background: #fff;
}

@media screen and (min-width: 48em) {
    .grid-container {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }
    .item1 {
        grid-column: 1 / 5;
        grid-row-end: span 2;
    }
    .item2 {
        grid-column: 5 / -1;
        grid-row-end: span 2;
    }
    .item3 {
        grid-column: 1 / 4;
        grid-row-end: span 2;
    }
    .item4 {
        grid-column: 4 / 10;
        grid-row-end: span 2;
    }
    .item5,
    .item6 {
        grid-column: 1 / 5;
    }    
}