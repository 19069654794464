.primary-color {
  color: #F9C2A2;
}

.secondary-color {
  color: #D1913C;
}

/* Styles de texte */
.title {
  font-family: 'Dancing Script', cursive;
  font-size: 3.2rem;
  margin-bottom: 20px;
}

.privacy-policy h1 {
  color: #333;
  text-align: center;
    font-size: 5rem; /* Ajustez la taille du texte comme vous le souhaitez */
  letter-spacing: 5px; /* Ajustez l'espacement entre les lettres comme vous le souhaitez */

}

h2 {
  color: #333; /* Couleur inchangée pour une meilleure visibilité */
  font-size: 3.5rem; /* Taille ajustée pour l'esthétique */
  margin: 20px 0; /* Gardez votre marge existante */
  text-transform: none; /* Style de texte inchangé */
  letter-spacing: 1px; /* Espacement entre les lettres inchangé */
  animation: fadeIn 1.5s ease-out; /* Animation fade-in pour les titres */
  font-family: 'Dancing Script', cursive; /* Police de caractères inchangée */
  text-align: center; /* Ajouté pour centrer le texte */
  display: block; /* Assurez-vous que h4 est traité comme un bloc pour centrer le texte correctement */
  width: 100%; /* Assurez-vous que h4 prend la largeur complète pour centrer le texte */
}
.last-updated {
  font-style: italic;
  margin-bottom: 5px;
  text-align: center;
}

.privacy-policy li {
  counter-increment: section;
  margin-bottom: 0px;
  font-family: Calibri, sans-serif;
  font-size: 1rem;
  letter-spacing: 1px;
  margin-left: 30px;
}



.privacy-policy li strong {
  font-weight: bold; /* Mettre en gras la partie du texte souhaitée */
}


.privacy-policy P {
  color: #333;
  text-align: left;
  font-size: 1rem;
  letter-spacing: 1px;
  font-family: Calibri, sans-serif;
    text-align: justify; 
}



@font-face {
  font-family: 'MalukoPolice'; /* Nom de votre police personnalisée */
  src: url('./fonts/FontsFree-Net-BeautifullyDelicious.ttf') format('truetype'); /* Chemin vers votre fichier TTF */
  font-weight: lighter; /* Poids de la police (normal, bold, etc.) */
  font-style: lighter; /* Style de la police (normal, italic, etc.) */
  unicode-range: U+0000-00FF, U+0100-017F, U+0180-024F;
}
