/* Styles existants */
.pdf-download-container {
  display: flex;
  justify-content: space-between; /* Sépare l'icône et le bouton */
  align-items: center;
  width: fit-content; /* S'adapte au contenu à l'intérieur */
  padding: 0;
  margin: auto; /* Centre le conteneur */
}

.download-icon {
  /* Taille de l'icône ajustée pour les écrans plus grands */
  width: 90px; /* Taille initiale pour les grands écrans */
  margin-right: 30px; /* Espace entre l'icône et le bouton */
}

.pdf-download-button {
  /* Styles existants pour votre bouton */
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  border: none;

  cursor: pointer;
  font-size: 25px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pdf-download-container {
    flex-direction: column; /* Empile l'icône et le bouton verticalement */
    align-items: center; /* Centre les éléments dans le conteneur */


  }

  .download-icon {
    margin-right: 0px; /* Retire l'espace sur les côtés pour les petits écrans */
    margin-bottom: 10px; /* Ajoute un espace en dessous pour les petits écrans */
    width: 80px; /* Réduit la taille de l'icône pour les petits écrans */
  }

  .pdf-download-button {
    width: auto; /* Permet au bouton de s'adapter au texte à l'intérieur */
    padding: 8px 16px; /* Ajuste le padding pour les petits écrans */
    font-size: 20px; /* Réduit la taille de la police pour les petits écrans */
  }
}

/* Ajoutez d'autres règles de media queries ici pour gérer différents points de rupture si nécessaire */
