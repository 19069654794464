/* PrivacyPolicy.css */
.privacy-policy {
  font-family: 'Arial', sans-serif;
  max-width: 1600px;
  margin: auto;
  padding: 10px;
  line-height: 1.6;
}



/* Responsive Design */
@media (max-width: 768px) {
  .privacy-policy {
    padding: 20px;
  }
}
.logo {
  display: block; /* Assurez-vous que l'image est affichée en tant que bloc */
  margin: 0 auto; /* Centrez horizontalement en utilisant une marge automatique */
}
