
.animated-list-item {
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateX(-50px);
  transition: all 2s ease-out;
  line-height: 0.9; /* Adjust line height to control space between lines */
   font-size: 1.3rem;
     font-family: 'Arial', sans-serif; 
       color: #333;
}

.comeIn {
  opacity: 1;
  transform: translateX(0);
}

.icon img {
  margin-right: 40px;
  width: 70px; /* Adjust size as needed */
  
}
.bullet-item {
  margin-left: 40px; /* Indent bullet points */
  line-height: 0.7; /* Adjust line height to control space between lines */
}
.bullet-item::before {
  content: '• '; /* Unicode character for bullet */
  color: #F48647; /* Color from the logo */
  font-size: 1.5em; /* Adjust the size of the bullet */
  vertical-align: middle; /* Align the bullet vertically */
  margin-right: 5px; /* Space between bullet and text */
}
 