/* Quisommesnous.css */

.about-us {
  font-family: 'Arial', sans-serif;
  color: #333;
  max-width: 1500px;
  margin: 0 auto;
 padding: 3em; /* Utilisation des ems pour le padding */
  animation: fadeIn 1s ease-out;
}

.about-intro,
.about-details {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px 10;
  animation: slideInFromLeft 1s ease-out forwards;
}

.intro-text {
  max-width: 800px;
  text-align: justify;
  margin-right: 1px;
  margin-left: 1px;
  animation: slideInFromRight 1s ease-out forwards;
   line-height: 1.2;
   font-size: 1.7rem
}

.intro-text h1 {
  font-family: 'Dancing Script', cursive; /* Add the Google font family here */
  color: #F9C2A2; /* Accent color from the logo */
  font-size: 3.2rem;
  margin-bottom: 20px;
}

.about-intro {
  flex-direction: row-reverse; /* Image on the left, text on the right */
}

.about-details {
  text-align: left;
  animation: slideInFromLeft 1s ease-out forwards;
}
.team-photo{
  width: 100%;
  max-width: 400px;
  border-radius: 10px; /* Soften the edges */
  box-shadow: 0 4px 8px rgb(0, 0, 0); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 50%;
}
.action-photo {
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  border-radius: 10px; /* Soften the edges */
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.466); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-top-left-radius: 50%; /* Round the top-left corner */
  border-top-right-radius: 50%; /* Round the top-right corner */
    border: 2px solid #D1913C; /* Second border with the chosen color */
    
}
.quantite {
  width: 100%;
  max-width: 500px; /* Adjust size based on layout */
  border-radius: 400px; /* Rounded corners */
  overflow: hidden; /* Hide content that extends beyond the border */
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.466);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
/* Hover effect for the image */
.quantite:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.tarifs {
  width: 100%;
  max-width: 500px; /* Adjust size based on layout */
  border-radius: 20px; /* Rounded corners */
  overflow: hidden; /* Hide content that extends beyond the border */
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.466);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
/* Hover effect for the image */
.tarifs:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}


.team-photo:hover,
.action-photo:hover {
  transform: scale(1.1); /* Enlarges the photo slightly */
  box-shadow: 0 6px 12px rgba(0,0,0,0.2); /* Makes the shadow deeper */
}
.pdf-download-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0px;
  margin: auto;

}

.pdf-download-container > * {
  margin: 10px;
}

.pdf-download-button {
  max-width: 100%; /* Limitez la largeur maximale du bouton à 100% */
  width: auto; /* Rend le bouton réactif pour s'adapter à la largeur du contenu */
  white-space: nowrap; /* Empêche le bouton de se retourner sur lui-même */
}
/* Responsive Design */
@media (max-width: 768px) {
    .action-photo, 
  .about-intro,
  .about-details {
    flex-direction: column;
    text-align: center;
  }
  
  .intro-text,
  .about-details p {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .team-photo,
  .action-photo {
    max-width: 100%;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInFromLeft {
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInFromRight {
  from { transform: translateX(50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

/* Headings Style */
h2 {
  color: #333; /* Changed to a darker color for better visibility */
  font-size: 4rem; /* Adjusted size for aesthetics */
  margin: 0px 0;
  text-align: center;
  text-transform: none; /* Added text styling */
  letter-spacing: 1px; /* Added letter spacing */
  animation: fadeIn 1.5s ease-out; /* Fade-in animation for headings */
    font-family: 'Dancing Script', cursive;
      display: block;
     width: 100%; 

}
h4 {
  color: #333; /* Couleur inchangée pour une meilleure visibilité */
  font-size: 3rem; /* Taille ajustée pour l'esthétique */
  margin: 20px 0; /* Gardez votre marge existante */
  text-transform: none; /* Style de texte inchangé */
  letter-spacing: 1px; /* Espacement entre les lettres inchangé */
  animation: fadeIn 1.5s ease-out; /* Animation fade-in pour les titres */
  font-family: 'Dancing Script', cursive; /* Police de caractères inchangée */
  text-align: center; /* Ajouté pour centrer le texte */
  display: block; /* Assurez-vous que h4 est traité comme un bloc pour centrer le texte correctement */
  width: 100%; /* Assurez-vous que h4 prend la largeur complète pour centrer le texte */
}


.icon-image {
  margin-right: 20px; /* Adjust as needed */
}

.icon-photo {
  width: 50px; /* Adjust size as needed */
  opacity: 0;
  transform: translateX(50px); /* Start off-screen */
  transition: opacity 0.5s ease, transform 0.5s ease;
}


.fadeIn {
  opacity: 1;
  transform: translateY(0);
}
.animated-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligner les enfants au début (gauche) */
  justify-content: center; /* Centrer verticalement si le conteneur a une hauteur spécifique */
  width: fit-content; /* Adapter la largeur au contenu */
  margin: auto;
   /* Centrage supplémentaire pour les éléments de niveau bloc, si nécessaire */
}
footer {
  margin-top: auto; /* Place le footer en bas */
  width: 100%; /* Le footer prend toute la largeur */
  padding: 0; /* Aucun padding */
  /* Ajoutez ici d'autres styles pour votre footer */
}