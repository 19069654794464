/* Contact.css - Complete with suggested improvements */

#contact {
  background: #FAF6EF;
  padding: 4rem 0;
  color: #333;
}

#contact h2 {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #333;
  font-family: 'Lobster', cursive;
  text-align: center; /* Center align the heading */
}

/* Wrapper for contact options and form */
.contact__layout {
  display: grid;
  grid-template-columns: minmax(30px, max-content) 1fr; /* Use minmax for flexible but bounded sizing on the first column */
  gap: 2rem; /* Space between columns */
  align-items: normal; /* Align the items to the top */
  justify-items: stretch; /* Stretch items to fill the grid areas */
  margin: 0 0; /* Center the layout grid */
  overflow-x: hidden;
  
}

.contact__options {
  padding: 1.5em;
  background: #fdf0e3;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex; /* Using flex to center content */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Horizontally center content in flex items */
  align-items: center; /* Vertically center content in flex items */
    text-align: center; 
}

.contact__option-icon {
  font-size: 7em;
  color: #ff7f50;
}
.contact__option h4 {
  color: #333;
  margin-bottom: 0.3em;
    text-align: center; 
        font-size: 1.5em;
}
.contact__option h5 {
  color: #ff7f50;
  margin-bottom: 0.5em;
  text-align: center; 
    font-size: 1.5em;
}

/* Styles for the form */
.contact__container form {
  display: flex;
  flex-direction: column;
  gap: 0em;
  padding: 1.5em;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style for input, textarea, select, and button inside the form */
form input,
form textarea,
form select,
form button {
  padding: 1em;
  margin-bottom: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

form button {
  background: #ff7f50;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

form button:hover {
  background-color: #e76f51;
}

/* Focus styles for inputs and textareas */
form input:focus,
form textarea:focus,
form select:focus {
  border-color: #ff7f50;
  box-shadow: 0 0 0 2px rgba(255, 127, 80, 0.5);
}

/* Accessibility improvements for form labels */
form label {
  display: block;
  margin-bottom: .5rem;
  font-weight: bold;
  color: #333;
}

/* Feedback message styles */
.form-feedback {
  margin-top: 1rem;
  font-weight: bold;
}
.form-success {
  color: green;
}
.form-error {
  color: red;
}
.name-fields {
  display: flex;
  gap: 1em; /* Add space between the two fields */
}

/* You may also want to adjust the flex properties of the inputs */
.name-fields input {
  flex: 1; /* This will allow the inputs to grow equally and fill the space */
  padding: 1em; /* Maintain padding from the original style */
  margin-bottom: 1em; /* Maintain margin from the original style */
  border: 1px solid #ddd; /* Maintain border from the original style */
  border-radius: 5px; /* Maintain border-radius from the original style */
  box-sizing: border-box; /* Maintain box-sizing from the original style */
}


/* Responsive design adjustments */
@media screen and (max-width: 1000px) {
  .contact__layout {
    grid-template-columns: 1fr; /* Full width on smaller screens */
  }

  #contact h2 {
    text-align: center; /* Center the heading text on smaller screens */
  }
  .name-fields {
    flex-direction: column; /* Stack them on top of each other on small screens */
  }
  .contact__options,
  .contact__container form {
    width: 100%;
    max-width: 100%;
  }
}

* {
  box-sizing: border-box;
}

