.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0vh;
  padding-bottom: 30px;
/* Utilisez la hauteur totale de la fenêtre pour centrer verticalement */
}

.social-media-icons {
  display: flex;
  align-items: center; /* Aligner verticalement le logo et le texte */
  justify-content: center;
  gap: 10px;
}

.icon {
  display: center;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
  overflow: hidden;
}

.icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon:hover {
  transform: scale(1.3);
}

.social-media-icons h2 {
  margin-left: 8px;
  font-size: 16px;
  color: #333;
  /* Autres styles h2 ici si nécessaire */
}

/* Styles spécifiques pour le hover de chaque plateforme de médias sociaux */
.facebook:hover {
  background-color: #3b5998;
}

.twitter:hover {
  background-color: #1da1f2;
}

/* .instagram:hover {
  background-color: rgba(245, 138, 72, 0.623);
} */
h2 {
  color: #333; /* Couleur inchangée pour une meilleure visibilité */
  font-size: 6rem; /* Taille ajustée pour l'esthétique */
  margin: 0px 0;
  text-align: center;
  text-transform: none; /* Style de texte inchangé */
  letter-spacing: 10px; /* Espacement entre les lettres inchangé */
  animation: fadeIn 1.5s ease-out; /* Animation fade-in pour les titres */
  font-family: 'MalukoPolice'; /* Remplacez 'MaPolice' par le nom de votre police personnalisée */
  font-weight: 100%; /* Contrôle précis du poids du caractère (70% du poids normal) */
  display: block;
  width: 100%;
  /* Pour les majuscules uniquement */
  font-variant-caps: titling-caps; /* Attnue le poids des majuscules */
}
@font-face {
  font-family: 'MalukoPolice'; /* Remplacez 'MaPolice' par le nom que vous souhaitez donner à votre police personnalisée */
  src: url('./../fonts/FontsFree-Net-BeautifullyDelicious.ttf') format('truetype'); /* Assurez-vous de spécifier le chemin correct vers votre fichier TTF */

}