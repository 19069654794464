.picture-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
}

.photo {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
}
.photo img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.photo-full { flex: 100%; height: 200px; }
.photo-wide { flex: 50%; height: 160px; }
.photo-tall { flex: 25%; height: 220px; }
.photo-big-square { flex: 33%; height: 180px; }
.photo-long { flex: 100%; height: 100px; }
.photo-small-square { flex: 20%; height: 100px; }
.photo-tiny { flex: 10%; height: 70px; }
.photo-medium { flex: 20%; height: 50px; }
.photo-large-tall { flex: 40%; height: 260px; }
.photo-panorama { flex: 100%; height: 160px; }
.photo-small-tall { flex: 20%; height: 200px; }
.photo-large-wide { flex: 60%; height: 140px; }
.photo-medium-square { flex: 25%; height: 140px; }
.photo-large-long { flex: 100%; height: 140px; }
.photo-extra-wide { flex: 80%; height: 120px; }
.photo-extra-tall { flex: 30%; height: 300px; }
.photo-small-long { flex: 30%; height: 100px; }
.photo-medium-tall { flex: 35%; height: 220px; }
.photo-extra-large { flex: 70%; height: 250px; }
.photo-extra-small { flex: 15%; height: 80px; }
.photo-style-21 { flex: 100%; height: 250px; /* autres propriétés */ }
.photo-style-22 { flex: 60%; height: 180px; /* autres propriétés */ }
.photo-style-23 { flex: 40%; height: 220px; /* autres propriétés */ }
.photo-style-24 { flex: 30%; height: 200px; /* autres propriétés */ }
.photo-style-25 { flex: 25%; height: 150px; /* autres propriétés */ }
.photo-style-26 { flex: 20%; height: 120px; /* autres propriétés */ }
.photo-style-27 { flex: 15%; height: 90px; /* autres propriétés */ }
.photo-style-28 { flex: 10%; height: 60px; /* autres propriétés */ }
.photo-style-29 { flex: 5%; height: 50px; /* autres propriétés */ }
.photo-style-30 { flex: 80%; height: 260px; /* autres propriétés */ }

/* Responsive Design */
@media (max-width: 768px) {
  .photo {
    flex-basis: calc(50% - 10px); /* Prend en compte l'espace entre les photos */
  }
  /* Hauteur uniforme pour les petits écrans pour tous les styles */
  .photo-tall, .photo-big-square, .photo-small-square, .photo-tiny, .photo-medium, .photo-large-tall, .photo-small-tall, .photo-large-wide, .photo-medium-square, .photo-large-long, .photo-extra-wide, .photo-extra-tall, .photo-small-long, .photo-medium-tall, .photo-extra-large, .photo-extra-small, .photo-style-21, .photo-style-22, .photo-style-23, .photo-style-24, .photo-style-25, .photo-style-26, .photo-style-27, .photo-style-28, .photo-style-29, .photo-style-30 {
    height: 100px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
