/* Base styles for the navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 55px;
  background-image: linear-gradient(to right, #A01808, #F48647); /* Gradient background */
  font-family: 'Signature', 'Arial', cursive;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
}

/* Animation keyframes */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Styles for the logo within the navbar */
.navbar-logo img {
  height: 53px;
}

/* Unordered list styles for menu items */
.navbar-menu {
  list-style-type: none;
  display: flex;
  gap: 30px;
}

/* List item styles */
.navbar-menu li {
  display: inline;
  font-size: 20px;
  font-family: 'Dancing Script', 

}

/* Link styles */
.navbar-menu li a {
  text-decoration: none;
  color: white; /* White text color */
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* Hover state for links */
.navbar-menu li a:hover {
  background-color: rgba(255, 255, 255, 0.3); /* Lighter shade for the button hover state */
  color: white; /* Keep text color white on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-menu {
    display: none; /* Hide the main menu */
    flex-direction: column;
    align-items: flex-end; /* Align menu items to the left */
    gap: 6px;
    font-size: 1.5rem;
    padding: 20px 30px;
    position: absolute;
    top: calc(100% - 35px);
    left: 0;
    right: 0;
    background-color: transparent; /* No background */
  }

  .navbar-menu li a {
    color: black; /* Text color in black */
    transition: color 0.3s ease, transform 0.3s ease;
  }

  .navbar-menu li a:hover {
    color: #F48647; /* Hover color */
    transform: scale(1.1); /* Zoom effect on hover */
  }
}
.navbar-menu.active {
  display: flex; /* Maintenant affiché lorsque actif */
  position: absolute; /* Position absolue pour le placer correctement */
  top: 100%; /* Juste en dessous de la navbar */
  left: 0;
  right: 0;

  z-index: 1001; /* Assurez-vous qu'il est au-dessus du fond flou */
}
/* Add padding to the top of the body */
body {
  padding-top: 80px;
}

/* Scrolled navbar styles */
.navbar.scrolled {
  background-color: #fde8de;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 25px 25px 25px 25px; /* More subtle rounded corners */
}

/* Optional: Add a slight scale effect on scroll */
.navbar.scrolled {
  transform: scale(0.98);
}

/* Styles for the hamburger icon */
.navbar-hamburger {
  display: none; /* Hidden by default */
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 30px; /* Icon width */
  height: 21px; /* Icon height */
  
}

.navbar-hamburger span {
  display: block;
  height: 3px;
  background-color: white;
  border-radius: 2px;
  transition: all 0.3s ease;
}

/* Animation for hamburger icon when clicked */
.navbar-hamburger.active span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.navbar-hamburger.active span:nth-child(2) {
  opacity: 0;
}

.navbar-hamburger.active span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 768px) {
  .navbar-hamburger {
    display: flex; /* Show icon on small screens */
  }
}

.navbar-menu.active {
  display: flex; /* Show the menu when active */
}
/* Styles pour le fond flou */
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.514);
  backdrop-filter: blur(10px); /* Effet de flou */
  z-index: 999; /* Assurez-vous que le fond flou est au-dessus de tout le reste */
}
