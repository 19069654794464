body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAF5EF;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Styles for the scrollbar track (background) */
::-webkit-scrollbar {
  width: 4px; /* Reduced width for a finer scrollbar */
}

/* Styles for the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #F48647; /* Semi-transparent color */
  border-radius: 4px; /* Rounded corners on the scrollbar handle */
}

/* Styles for the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #A01808 /* Darker and semi-transparent color on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* 'thin' for a finer scrollbar */
  scrollbar-color: rgba(249, 194, 162, 0.7) #FAF6EF; /* Thumb and track color, semi-transparent */
}