/* Conteneur pour centrer et ajouter un effet de fondu sur les côtés */
.image-grid-container {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  padding: 10px;
  animation: scroll 30s linear infinite; /* Animation de défilement */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Ajustez cette valeur si nécessaire */
  }
}

.image-grid img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.image-grid img:hover {
  transform: scale(1.05);
}

/* Pseudo-éléments pour l'effet de transparence sur les côtés */
.image-grid-container::before, .image-grid-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px; /* Ajustez la largeur de l'effet de fondu */
  z-index: 1;
}

.image-grid-container::before {
  left: 0;
  background: linear-gradient(to right, #fff, transparent);
}

.image-grid-container::after {
  right: 0;
  background: linear-gradient(to left, #fff, transparent);
}

@media (max-width: 600px) {
  .image-grid {
    grid-template-columns: 1fr;
  }
}
