h4 {
  color: #333; /* Couleur inchangée pour une meilleure visibilité */
  font-size: 3rem; /* Taille ajustée pour l'esthétique */
  margin: 10px 0; /* Gardez votre marge existante */
  text-transform: none; /* Style de texte inchangé */
  letter-spacing: 1px; /* Espacement entre les lettres inchangé */
  animation: fadeIn 1.5s ease-out; /* Animation fade-in pour les titres */
  font-family: 'Dancing Script', cursive; /* Police de caractères inchangée */
  text-align: center; /* Ajouté pour centrer le texte */
  display: block; /* Assurez-vous que h4 est traité comme un bloc pour centrer le texte correctement */
  width: 100%; /* Assurez-vous que h4 prend la largeur complète pour centrer le texte */
}
.contactez-nous-container {
  max-width: 1700px;
  margin: 0px auto;
  padding: 20px;
  /* Ajoutez ici d'autres styles que vous souhaitez appliquer au conteneur */
}
/* ContactezNous.css */

.contact-us {
  font-family: 'Arial', sans-serif;
  color: #333;
  max-width: 1700px;
  margin: 0 auto;
  padding: 10px;
  animation: fadeIn 1s ease-out;
}

.contact-intro,
.contact-details {
  /* Réutilisez ou ajustez ces styles selon les besoins de votre nouvelle page */
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px 10;
  animation: slideInFromLeft 1s ease-out forwards;
}

.contact-text {
  /* Ajustez ces styles pour correspondre à la mise en page de votre nouvelle page */
  max-width: 800px;
  text-align: justify;
  margin-right: 1px;
  margin-left: 1px;
  animation: slideInFromRight 1s ease-out forwards;
  line-height: 1.2;
  font-size: 1.7rem; /* Ajustez la taille de la police si nécessaire */
}

/* Dans votre fichier CSS existant (Contacteznous.css ou un autre) */



