/* HomePage.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  padding-top: 50px;
}

.home {
  text-align: center;
}

.hero {
  background-color: #FAF5EF; /* Light background from the logo */
  padding: 50px 20px;
  animation: fadeIn 2s ease;
}

.logo {
  max-width: 300px;
  animation: bounce 1s infinite alternate;
}

/* HomePage.css */



h1 {
  color: #333; /* Dark color for contrast */
  margin: 5px 0px;
  margin-top: 5px;

}

p {
  color: #555; /* Slightly lighter color for the subtitle */
    letter-spacing: 0.8px;
    margin: 10px

}





/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounce {
  from { transform: translateY(0); }
  to { transform: translateY(-15px); }
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero {
    padding: 30px 10px;
  }

  .shop-now-btn {
    padding: 8px 16px;
  }
}
.button-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Ajustez l'espace entre les boutons */
}

/* Si vous souhaitez que les boutons restent en colonne sur les petits écrans */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    gap: 10px;
  }
}


.shop-now-btn {
  background-color: #F9C2A2; /* Couleur d'accent à partir du logo */
  color: #333;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  text-decoration: none; /* Supprimer le soulignement */
  display: inline-block; /* Pour le bon alignement et le padding */
}

.shop-now-btn:hover {
  background-color: #F89B80; /* Darker shade for the button hover state */
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounce {
  from { transform: translateY(0); }
  to { transform: translateY(-15px); }
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero {
    padding: 30px 10px;
  }

  .shop-now-btn {
    padding: 8px 16px;
  }
}
.button-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Ajustez l'espace entre les boutons */
}

/* Si vous souhaitez que les boutons restent en colonne sur les petits écrans */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    gap: 10px;
  }
}
.feature-image {
  max-width: 100%; /* Assure que l'image est responsive */
  height: auto; /* Maintient le ratio de l'image */
  margin-top: 20px; /* Espace au-dessus de l'image */
    opacity: 0.5; 
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Responsive design pour les boutons */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column; /* Les boutons s'empilent verticalement sur les petits écrans */
  }
}