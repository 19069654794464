@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.animated-words-container {
  position: relative;
  width: 100%; /* Full container width */
  height: 200px; /* Adjust height as needed */
  overflow: hidden;
   padding-bottom: 10px; /* Added padding at the bottom */
   
}

.animated-word {
  position: absolute;
  white-space: nowrap;
  font-size: 7em; /* Increased font size */
  left: 100%; /* Start off-screen to the right */
  animation: moveWord 6s linear infinite; /* Adjusted duration for timing */
}

/* Keyframes for moving words */
@keyframes moveWord {
  0% { transform: translateX(0); } /* Start just off-screen to the right */
  25% { transform: translateX(-70%); } /* Adjust to ensure full visibility */
  75% { transform: translateX(-192%); } /* Adjust to ensure full visibility */
  100% { transform: translateX(-255%); } 

}

/* Responsive adjustments */
@media (max-width: 768px) {
  .animated-word {
    font-size: 6em; /* Smaller font size on smaller screens */
  }
}
