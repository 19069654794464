/* Styles généraux pour la page de la galerie */
.galerie-page {
  padding: 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
  padding-top: 50px;
  

}

h4 {
  color: #333; /* Couleur inchangée pour une meilleure visibilité */
  font-size: 3rem; /* Taille ajustée pour l'esthétique */
  margin: 20px 0; /* Gardez votre marge existante */
  text-transform: none; /* Style de texte inchangé */
  letter-spacing: 1px; /* Espacement entre les lettres inchangé */
  animation: fadeIn 1.5s ease-out; /* Animation fade-in pour les titres */
  font-family: 'Dancing Script', cursive; /* Police de caractères inchangée */
  text-align: center; /* Ajouté pour centrer le texte */
  display: block; /* Assurez-vous que h4 est traité comme un bloc pour centrer le texte correctement */
  width: 100%; /* Assurez-vous que h4 prend la largeur complète pour centrer le texte */
}
/* Styles pour le conteneur de la galerie */

.picture-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: auto;
  max-width: 1500px;
}
/* Styles pour chaque image */
.photo {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  width: calc(25% - 20px); /* Ajuster la largeur des images */
  height: auto; /* Hauteur auto pour garder les proportions */
  position: relative;
    cursor: pointer;
}
.photo img {
  width: 100%;
  height: 100%; /* Assurez-vous que l'image remplit complètement le conteneur */
  object-fit: cover;
}
.photo:hover {
  transform: scale(1.02); /* Légère augmentation de la taille au survol */
}

/* Responsive design */
@media (max-width: 992px) { /* Taille moyenne */
  .galerie-page {
    padding: 40px; /* Augmentation du padding sur les écrans moyens */
  }
}

@media (max-width: 768px) {
  .photo-square, .photo-vertical, .photo-large, .photo-tall {
    flex-basis: 100%;
  }

  @media (max-width: 480px) {
    .photo {
      width: 100%;
    }
  }
}
