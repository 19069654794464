.site-footer {
  background-image: linear-gradient(to right, #A01808, #F48647);
  color: white;
  display: flex;
  flex-direction: column; /* Keep main axis vertical */
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  width: 100%;
  /* position: absolute;  */
  bottom: 0;
  left: 0;
}
.footer-top {
  display: flex;
  flex-direction: row;
  gap: 20%;
  justify-content: center;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 12px;
}
.footer-bottom {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 12px 50px;
  border-top: 1px solid #E5E5E5;
  width: 100%;
  font-size: 0.7rem;
  padding-bottom: 0px;
}

.footer-section h2 {

  font-family: 'Dancing Script', cursive;
  color: white;
  font-size: 2rem; /* Adjusted font size */
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.footer-section p {
  margin: 5px 0;
}

.footer-section p a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 0.8rem; /* Adjusted font size */
  letter-spacing: 1px;
}

.footer-section p a:hover {
  color: #F9C2A2;
}
/* 
.footer-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #E5E5E5;
  width: 100%;
  font-size: 0.7rem;
} */

.footer-bottom a {
  color: white;
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 0 10px;
}

.footer-bottom a:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .site-footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    width: 100%; /* Full width for mobile */
  }
.footer-top,
  .footer-bottom {
    flex-direction: column;
  }

  .footer-bottoma {
    margin-bottom: 10px;
  }
}

/* Additional styles to ensure the footer stays at the bottom */
body, html {
  height: 100%; /* Set body and html to 100% height */
}

body {
  position: relative; /* Relative position for the body to contain the footer absolutely */
  margin-bottom: 120px; /* Space for the footer */
}

/* Ensure the page content doesn't hide behind the footer */
.content {
  padding-bottom: 150px; /* Space at the bottom for content */
}
